import React, {
	useEffect, useContext, useState, useRef, useCallback,
} from 'react';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import Head from 'next/head';
import {
	Flex, Box, Image, Heading, Link as ChakraLink, Icon, IconButton,
	Button, Input, Text, Tooltip, Fade,
	useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody,
	useBreakpointValue, Container,
} from '@chakra-ui/react';
import NProgress from 'nprogress';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
	faInfinity, faMap, faSeedling, faStar, faUserFriends,
	faPlay,
	faBars,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import zhTW from 'date-fns/locale/zh-TW';
import Cursor from './Cursor';
import { ContextStore } from '../contexts/authContext';
import { NAV_LAYER } from '../utils/nav';
// import { getDay } from 'date-fns';

export default function Home({
	title, children, views, defaultNav,
}) {
	const router = useRouter();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = useRef();
	const { rangeState, setRangeState } = useContext(ContextStore);
	setDefaultLocale(zhTW);
	const [isCustomized, setIsCustomized] = useState(false); // 是否為自訂區間
	const [period, setPeriod] = useState(10); // 時間區間
	const [mode, setMode] = useState('y'); // 年檢視 月檢視

	const [isOpenNav, setIsOpenNav] = useState(false); // NAV 是否打開
	const [currentLayer, setCurrentLayer] = useState(''); // 點選哪個 layer

	const toggleNav = useCallback((layer) => {
		if (currentLayer === layer && isOpenNav) {
			setIsOpenNav(false);
		} else {
			setCurrentLayer(layer);
			setIsOpenNav(true);
		}
	}, [isOpenNav, currentLayer]);

	useEffect(() => {
		let to;
		if (defaultNav) {
			to = setTimeout(() => {
				toggleNav(defaultNav);
			}, 500);
		}
		return () => {
			if (to) clearTimeout(to);
		};
	}, [defaultNav]);

	// 手機版是否顯示漢堡選單
	const mobileMenuShow = useBreakpointValue({
		base: true, md: false,
	});

	// const { data: _graphs } = useSWR('/api/v1/tables');

	// 一開始都不要去拿資料 之後 mutate 拿一次
	const [refreshTimes, setRefreshTimes] = useState(0);
	const { data: _graphs, mutate } = useSWR('/api/v1/tables', {
		revalidateOnFocus: false,
		revalidateOnMount: false,
		revalidateOnReconnect: false,
		refreshWhenOffline: false,
		refreshWhenHidden: false,
		refreshInterval: 0,
	});
	const [graphs, setGraphs] = useState([]);

	useEffect(() => {
		if (_graphs) {
			// const badTables = BAD_TABLES;
			// setGraphs(_graphs.filter((x) => !badTables.includes(x.shortName)));
			setGraphs(_graphs);
		}
	}, [_graphs]);

	useEffect(() => {
		if (refreshTimes === 0) {
			mutate();
			setRefreshTimes(refreshTimes + 1);
		}
	}, [refreshTimes]);

	useEffect(() => {
		const handleStart = () => {
			NProgress.start();
		};
		const handleStop = () => {
			NProgress.done();
			// 等到進度條跑完才把 NAV 關掉
			setIsOpenNav(false);
			setCurrentLayer('');
			if (mobileMenuShow) {
				onClose();
			}
		};

		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleStop);
		router.events.on('routeChangeError', handleStop);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleStop);
			router.events.off('routeChangeError', handleStop);
		};
	}, [router]);

	useEffect(() => {
		setRangeState({ unit: mode, start: dayjs().subtract(period, 'year'), end: dayjs() });
	}, [period, mode]);

	return (
		<>
			<Cursor />
			<Box maxW="100vw">
				<Head>
					<title>
						{title ? `${title} - ` : ''}
						新北市藝文儀表板
					</title>
				</Head>
				<Flex minH="100vh">
					<Box
						w="100%"
						color="title"
						bgAttachment="fixed"
						bgImage={['radial-gradient(ellipse farthest-side at bottom right, #3BF3FC 0%, #316585 15%, #001D4E 100%)']}
					>
						<Box as="header" py={3} pt={8} ml={8}>
							<Flex justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }} onClick={() => setIsOpenNav(false)}>
								<Flex
									color="navText"
									flexWrap="wrap"
									flexDirection="row"
									justifyContent="space-between"
									alignItems="center"
									mr={2}
									mb={{ base: 5, md: 0 }}
								>
									<ChakraLink as={NextLink} href="/" textDecoration="none" _hover={{ textDecoration: 'none' }}>
										<Flex alignItems="center" px={5}>
											<Image src="/logo.svg" width="32px" alt="Logo" mr={3} />
											<Heading as="h2" fontSize="xl" letterSpacing="1px" mb={1}>
												新北市藝文儀表板
											</Heading>
										</Flex>
									</ChakraLink>
									{router.pathname !== '/404' && router.pathname !== '/500' ? (
										<>
											{mobileMenuShow ? (
												<IconButton
													bgColor="transparent"
													_hover={{ bgColor: 'transparent' }}
													color="white"
													// mx="auto"
													icon={<Icon as={Fa} icon={faBars} />}
													ref={btnRef}
													onClick={(e) => { onOpen(); setIsOpenNav(true); setCurrentLayer('文化平權'); e.stopPropagation(); }}
													p={0}
													mx={0}
												/>
											) : null}
											{mobileMenuShow ? (
												<Drawer
													size="full"
													isOpen={isOpen}
													placement="right"
													onClose={onClose}
													finalFocusRef={btnRef}
												>
													<DrawerOverlay />
													<DrawerContent bgColor="background" pt={6}>
														<DrawerCloseButton color="white" />
														<DrawerBody color="white" mx={0} px={0}>
															{isOpenNav ? (
																<MainNav
																	mobileMenuShow={mobileMenuShow}
																	isOpenNav={isOpenNav}
																	setIsOpenNav={setIsOpenNav}
																	btnRef={btnRef}
																	onClose={onClose}
																	currentLayer={currentLayer}
																	setCurrentLayer={setCurrentLayer}
																	graphs={graphs}
																/>
															) : null}
														</DrawerBody>
													</DrawerContent>
												</Drawer>
											) : (
												<Flex
													flexWrap="nowrap"
													flexDirection={{ base: 'column', md: 'row' }}
													ml={8}
												>
													<Button
														mx={{ base: 0, md: 3 }}
														my={{ base: 1, md: 0 }}
														bgColor="transparent"
														color="white"
														variant="link"
														// leftIcon={<Icon mr={1} as={Fa} icon={faUserFriends} />}
														onClick={(e) => {
															toggleNav('文化平權');
															e.stopPropagation();
														}}
													>
														<Icon mr={1} as={Fa} icon={faUserFriends} />
														文化平權
													</Button>
													<Button
														mx={{ base: 0, md: 3 }}
														my={{ base: 1, md: 0 }}
														bgColor="transparent"
														color="white"
														variant="link"
														// leftIcon={<Icon mr={1} as={Fa} icon={faStar} />}
														onClick={(e) => {
															toggleNav('文化多元');
															e.stopPropagation();
														}}
													>
														<Icon mr={1} as={Fa} icon={faStar} />
														文化多元
													</Button>
													<Button
														mx={{ base: 0, md: 3 }}
														my={{ base: 1, md: 0 }}
														bgColor="transparent"
														color="white"
														variant="link"
														// leftIcon={<Icon mr={1} as={Fa} icon={faSeedling} />}
														onClick={(e) => {
															toggleNav('文化創生');
															e.stopPropagation();
														}}
													>
														<Icon mr={1} as={Fa} icon={faSeedling} />
														文化創生
													</Button>
													<Button
														mx={{ base: 0, md: 3 }}
														my={{ base: 1, md: 0 }}
														bgColor="transparent"
														color="white"
														variant="link"
														// leftIcon={<Icon mr={1} as={Fa} icon={faInfinity} />}
														onClick={(e) => {
															toggleNav('文化永續');
															e.stopPropagation();
														}}
													>
														<Icon mr={1} as={Fa} icon={faInfinity} />
														文化永續
													</Button>
													<Button
														as={NextLink}
														href="/sitemap"
														mx={{ base: 0, md: 3 }}
														my={{ base: 1, md: 0 }}
														bgColor="transparent"
														color="white"
														variant="link"
														// leftIcon={<Icon mr={1} as={Fa} icon={faMap} />}
														onClick={(e) => {
															setIsOpenNav(false);
															setCurrentLayer('');
															e.stopPropagation();
														}}
													>
														<Icon mr={1} as={Fa} icon={faMap} />
														網站導覽
													</Button>
												</Flex>
											)}
										</>
									) : null}
								</Flex>
								{router.pathname !== '/' && router.pathname !== '/404' && router.pathname !== '/500' ? (
									<Box mx={5} color="#d3d3d3">
										<Flex alignItems="center">
											<Button
												size="sm"
												fontWeight={600}
												p={3}
												mr={1}
												minW={5}
												h={5}
												bgColor="transparent"
												color="white"
												onClick={() => {
													setPeriod(10);
													setMode('y');
													setIsCustomized(false);
												}}
												_hover={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												_active={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												isActive={period === 10 && !isCustomized}
											>
												近十年
											</Button>
											<Button
												size="sm"
												fontWeight={600}
												p={3}
												mr={1}
												minW={5}
												h={5}
												bgColor="transparent"
												color="white"
												onClick={() => {
													setPeriod(5);
													setMode('y');
													setIsCustomized(false);
												}}
												_hover={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												_active={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												isActive={period === 5 && !isCustomized}
											>
												近五年
											</Button>
											<Button
												size="sm"
												fontWeight={600}
												p={3}
												mr={1}
												minW={5}
												h={5}
												bgColor="transparent"
												color="white"
												onClick={() => {
													setPeriod(1);
													setMode('m');
													setIsCustomized(false);
												}}
												_hover={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												_active={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												isActive={period === 1 && !isCustomized}
											>
												近一年
											</Button>
											<Button
												size="sm"
												fontWeight={600}
												p={3}
												minW={5}
												h={5}
												bgColor="transparent"
												color="white"
												onClick={() => {
													setIsCustomized(!isCustomized);
													setMode('y');
												}}
												_hover={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												_active={{
													color: 'primary',
													bgColor: 'whiteAlpha.300',
												}}
												isActive={isCustomized}
											>
												自訂區間
											</Button>
										</Flex>
									</Box>
								) : null}
							</Flex>
							<Box mx={5} color="#d3d3d3">
								{isCustomized ? (
									<Flex justifyContent="flex-end" alignItems="center">
										<Tooltip label="起始日">
											<Box w="60px" zIndex={99200}>
												<DatePicker
													dateFormat="yyyy/MM"
													selected={dayjs(rangeState.start).toDate()}
													onChange={(date) => setRangeState({ start: dayjs(date).format('YYYY-MM-DD') })}
													startDate={dayjs(rangeState.start).toDate()}
													endDate={dayjs(rangeState.end).toDate()}
													locale="zh"
													showMonthYearPicker
													customInput={<Input fontSize="sm" h={6} fontWeight={600} border="0" py={1} px={0} mx={0} textAlign="center" outline={0} />}
												/>
											</Box>
										</Tooltip>
										<Text fontSize="sm" fontWeight={600}>~</Text>
										<Tooltip label="結尾日">
											<Box w="60px" zIndex={99200}>
												<DatePicker
													dateFormat="yyyy/MM"
													selected={dayjs(rangeState.end).toDate()}
													onChange={(date) => setRangeState({ end: dayjs(date).format('YYYY-MM-DD') })}
													startDate={dayjs(rangeState.start).toDate()}
													endDate={dayjs(rangeState.end).toDate()}
													minDate={dayjs(rangeState.start).toDate()}
													locale="zh"
													selectsEnd
													showMonthYearPicker
													customInput={<Input fontSize="sm" h={6} fontWeight={600} border="0" py={1} px={0} mx={0} textAlign="center" outline={0} />}
												/>
											</Box>
										</Tooltip>
										<Text fontSize="sm" fontWeight={600}> · </Text>
										<Tooltip label="年檢視">
											<Button
												size="sm"
												fontWeight={600}
												p={0}
												minW={5}
												h={5}
												bgColor={rangeState.unit === 'y' ? 'white' : 'transparent'}
												color={rangeState.unit === 'y' ? '#333' : '#ccc'}
												onClick={() => setRangeState({ unit: 'y' })}
												mr={2}
											>
												年
											</Button>
										</Tooltip>
										<Tooltip label="月檢視">
											<Button
												size="sm"
												fontWeight={600}
												p={0}
												minW={5}
												h={5}
												bgColor={rangeState.unit === 'm' ? 'white' : 'transparent'}
												color={rangeState.unit === 'm' ? '#333' : '#ccc'}
												onClick={() => setRangeState({ unit: 'm' })}
											>
												月
											</Button>
										</Tooltip>
									</Flex>
								) : null}
							</Box>
						</Box>
						{!mobileMenuShow ? (
							<Fade in={isOpenNav}>
								{isOpenNav ? (
									<MainNav
										mobileMenuShow={mobileMenuShow}
										isOpenNav={isOpenNav}
										setIsOpenNav={setIsOpenNav}
										btnRef={btnRef}
										onClose={onClose}
										currentLayer={currentLayer}
										setCurrentLayer={setCurrentLayer}
										graphs={graphs}
									/>
								) : null}
							</Fade>
						) : null}
						{router.pathname === '/' ? (
							<Box as="main" mt={8} px={0}>
								{children}
							</Box>
						) : (
							<Box as="main" mt={8} my={2} px={[2, 8]}>
								{children}
							</Box>
						)}
					</Box>
				</Flex>
			</Box>
			<Box as="footer" bgColor="background" color="bgGray" py={3}>
				<Container maxW="container.xl" textAlign="center">
					<Text>
						版權所有 © 2022~
						{new Date().getFullYear()}
						{' '}
						All Rights Reserved.
					</Text>
					<Text>
						瀏覽人次：
						{views ? views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
					</Text>
				</Container>
			</Box>
		</>
	);
}

function MainNav({
	mobileMenuShow, setIsOpenNav, setCurrentLayer, currentLayer, isOpenNav, graphs,
}) {
	return (
		<Box
			w="100%"
			// h="100vh"
			overflowY={mobileMenuShow ? 'scroll' : 'initial'}
			// position="absolute"
			top={{ base: '0', md: '20' }}
			// zIndex={12000}
			onClick={() => setIsOpenNav(false)}
		>
			<Box
				w="100%"
				minH={{ base: '100vh', md: 'auto' }}
				px={3}
				// bgColor="#466082"
				// boxShadow="0px 2px 3px 1px #111"
				onClick={(e) => e.stopPropagation()}
			>
				{/* <IconButton
					size="lg"
					position="absolute"
					top={0}
					right={0}
					bgColor="transparent"
					_hover={{ bgColor: 'transparent' }}
					color="white"
					mx="auto"
					icon={<Icon as={Fa} icon={faWindowClose} />}
					ref={btnRef}
					p={0}
					onClick={(e) => {
						e.stopPropagation();
						onClose();
						setIsOpenNav(false);
						setCurrentLayer('');
					}}
				/> */}
				<Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap">
					<Flex
						display={{ base: 'flex', md: 'none' }}
						w={{ base: '90%', md: '20%' }}
						px={8}
						mb={{ base: 4, md: 0 }}
						direction="column"
						alignItems="center"
					>
						<Flex direction="column" justifyContent="center" alignItems="center" mt={10}>
							<Button
								w="200px"
								mb={2}
								border="1px"
								borderColor="gray.200"
								bgColor="transparent"
								color="white"
								colorScheme="gray"
								leftIcon={mobileMenuShow ? <Icon as={Fa} icon={faUserFriends} /> : null}
								rightIcon={currentLayer === '文化平權' && isOpenNav ? (
									<Icon as={Fa} icon={faPlay} w={3} h={3} />
								) : null}
								onClick={() => {
									setIsOpenNav(true);
									setCurrentLayer('文化平權');
								}}
								_hover={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								_active={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								isActive={currentLayer === '文化平權' && isOpenNav}
							>
								文化平權
							</Button>
							<Button
								w="200px"
								mx="10%"
								mb={2}
								border="1px"
								borderColor="gray.200"
								bgColor="transparent"
								color="white"
								colorScheme="gray"
								leftIcon={mobileMenuShow ? <Icon as={Fa} icon={faStar} /> : null}
								rightIcon={currentLayer === '文化多元' && isOpenNav ? (
									<Icon as={Fa} icon={faPlay} w={3} h={3} />
								) : null}
								onClick={() => {
									setIsOpenNav(true);
									setCurrentLayer('文化多元');
								}}
								_hover={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								_active={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								isActive={currentLayer === '文化多元' && isOpenNav}
							>
								文化多元
							</Button>
							<Button
								w="200px"
								mx="10%"
								mb={2}
								border="1px"
								borderColor="gray.200"
								bgColor="transparent"
								color="white"
								colorScheme="gray"
								leftIcon={mobileMenuShow ? <Icon as={Fa} icon={faSeedling} /> : null}
								rightIcon={currentLayer === '文化創生' && isOpenNav ? (
									<Icon as={Fa} icon={faPlay} w={3} h={3} />
								) : null}
								onClick={() => {
									setIsOpenNav(true);
									setCurrentLayer('文化創生');
								}}
								_hover={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								_active={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								isActive={currentLayer === '文化創生' && isOpenNav}
							>
								文化創生
							</Button>
							<Button
								w="200px"
								mx="10%"
								mb={2}
								border="1px"
								borderColor="gray.200"
								bgColor="transparent"
								color="white"
								colorScheme="gray"
								leftIcon={mobileMenuShow ? <Icon as={Fa} icon={faInfinity} /> : null}
								rightIcon={currentLayer === '文化永續' && isOpenNav ? (
									<Icon as={Fa} icon={faPlay} w={3} h={3} />
								) : null}
								onClick={() => {
									setIsOpenNav(true);
									setCurrentLayer('文化永續');
								}}
								_hover={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								_active={{
									color: 'pryBlue',
									bgColor: 'white',
								}}
								isActive={currentLayer === '文化永續' && isOpenNav}
							>
								文化永續
							</Button>
							{mobileMenuShow ? (
								<Button
									as={NextLink}
									href="/sitemap"
									w="200px"
									mx="10%"
									mb={2}
									border="1px"
									borderColor="gray.200"
									bgColor="transparent"
									color="white"
									colorScheme="gray"
									leftIcon={<Icon as={Fa} icon={faMap} />}
									onClick={(e) => {
										setIsOpenNav(false);
										setCurrentLayer('');
										e.stopPropagation();
									}}
								>
									網站導覽
								</Button>
							) : null}
						</Flex>
					</Flex>
					{NAV_LAYER.find((n) => n.layer === currentLayer)?.subLayer && NAV_LAYER.find((n) => n.layer === currentLayer)?.subLayer.map((s) => (
						<Box w={{ base: '100%', md: '29.3%' }} mx="2%" mb={{ base: 4, md: 0 }}>
							<Box width="100%" borderBottom="1px" my={4} mt={8}>
								<Text pb={4} fontSize="sm" fontWeight="bold">{s.name}</Text>
							</Box>
							<Flex flexWrap="wrap">
								{s.item.map((ss) => (
									<Box w={{ base: '90%', md: '31.3%' }} mx="1%">
										<Box mb={4}>
											<Text mb={1} fontSize="sm" fontWeight="bold">{ss.name}</Text>
											{ss.item?.map((g) => (
												<div>
													{graphs.findIndex((x) => x.shortName === g) >= 0 ? (
														<ChakraLink as={NextLink} href={`/graph/${graphs.find((x) => x.shortName === g)?.shortName}`} textDecoration="none">
															<Button
																colorScheme="gray"
																variant="link"
																color="whiteAlpha.800"
																fontWeight="thin"
																size="sm"
																whiteSpace="pre-wrap"
																textAlign="left"
															>
																{graphs.find((x) => x.shortName === g)?.name}
															</Button>
														</ChakraLink>
													) : null}
												</div>
											))}
										</Box>
									</Box>
								))}
							</Flex>
						</Box>
					))}
				</Flex>
			</Box>
		</Box>
	);
}
