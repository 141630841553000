import React, { useState, useEffect } from 'react';

export default function Cursor() {
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const mMove = (el) => {
		setPosition({ x: el.clientX, y: el.clientY });
	};
	useEffect(() => {
		const addEventListeners = () => {
			document.addEventListener('mousemove', mMove);
		};
		const removeEventListeners = () => {
			document.removeEventListener('mousemove', mMove);
		};
		addEventListeners();
		return () => removeEventListeners();
	}, []);
	return (
		<>
			<div
				className="cursor"
				style={{
					left: `${position.x}px`,
					top: `${position.y}px`,
				}}
				bgImage={['radial(ellipse farthest-side at center,#3BF3FC 0%, #001D4E 70%)']}
			/>
			<style jsx>
				{`
					.cursor {
						width: 0px;
						height: 0px;
						border-radius: 100%;
						position: fixed;
						transform: translate(-50%, -50%);
						box-shadow: 0 0 20px 10px #3BF3FC;
					}
				`}
			</style>
		</>
	);
}
