export const BAD_TABLES = Object.freeze(['A103', 'C605', 'C609', 'C610', 'C611', 'C612', 'C613', 'C801', 'C901',
	'A104', 'A304', 'A310', 'A314', 'A315', 'A316', 'B302', 'C131', 'C403', 'D101', 'D102', 'D103', 'D201', 'D301', 'D302']);

export const IMAGE_TABLES = Object.freeze([
	{
		table: 'A301',
		titleKey: '館舍名稱',
	},
	{
		table: 'A401',
		titleKey: '作品名稱',
	},
	{
		table: 'B101',
		titleKey: '名稱',
	},
	{
		table: 'C301',
		titleKey: '場地名稱',
	},
	{
		table: 'C302',
		titleKey: '場地名稱',
	},
]);

export const NAV_LAYER = Object.freeze([
	{
		layer: '文化平權',
		link: 'equality',
		subLayer: [
			{
				name: '藝文場館設施服務',
				item:
				[
					{
						name: '多元參與',
						item: ['A404', 'A405', 'A403'],
					},
					{
						name: '參與情形',
						item: ['A102', 'A104'],
					},
					{
						name: '公立博物館典藏成果',
						item: ['C501'],
					},
				],
			},
			{
				name: '新北閱讀環境',
				item:
				[
					{
						name: '館舍分佈',
						item: ['A301'],
					},
					{
						name: '服務概況',
						item: ['A306', 'A303', 'A305', 'A317'],
					},
					{
						name: '推廣活動',
						item: ['A313', 'A307', 'B701', 'A304', 'A310', 'A314', 'A315', 'A316'],
					},
				],
			},
			{
				name: '藝文走入街區',
				item:
				[
					{
						name: '街頭藝術',
						item: ['C201', 'C301', 'C302'],
					},
					{
						name: '公共藝術',
						item: ['A401'],
					},
					{
						name: '行動博物館',
						item: ['A402'],
					},
				],
			},
		],
	},
	{
		layer: '文化多元',
		link: 'pluralism',
		subLayer: [
			{
				name: '地方節慶活動',
				item:
				[
					{
						name: '地方節慶',
						item: ['B401'],
					},
				],
			},
			{
				name: '城市美學廊帶',
				item:
				[
					{
						name: '府中美學',
						item: ['B502'],
					},
					{
						name: '礦業文化',
						item: ['B503'],
					},
					{
						name: '古蹟文化',
						item: ['B504'],
					},
					{
						name: '茶山文化',
						item: ['B505'],
					},
					{
						name: '三鶯文化',
						item: ['B506'],
					},
				],
			},
			{
				name: '國際藝文交流',
				item:
				[
					{
						name: '國際金工大賽',
						item: ['C607'],
					},
					{
						name: '陶博國際交流',
						item: ['C608'],
					},
					{
						name: '文化大使',
						item: ['B601'],
					},
				],
			},
		],
	},
	{
		layer: '文化創生',
		link: 'creation',
		subLayer: [
			{
				name: '藝文專業人才',
				item:
				[
					{
						name: '藝文補助',
						item: ['C701', 'C104'],
					},
					{
						name: '人才培育',
						item: ['C101', 'C103', 'C111'],
					},
					{
						name: '徵選競賽',
						item: ['C606', 'C602', 'C604', 'C121', 'C603'],
					},
				],
			},
			{
				name: '社區營造創生',
				item:
				[
					{
						name: '社區營造獎補助',
						item: ['C801', 'C802'],
					},
				],
			},
			{
				name: '生活美感扎根',
				item:
				[
					{
						name: '藝文展演活動',
						item: ['B501'],
					},
					{
						name: '藝術教育',
						item: ['A407'],
					},
					{
						name: '館校合作',
						item: ['A406'],
					},
				],
			},
		],
	},
	{
		layer: '文化永續',
		link: 'technology',
		subLayer: [
			{
				name: '文化資產活動',
				item:
				[
					{
						name: '文資概況',
						item: ['B101', 'B201', 'B303', 'B302', 'C131'],
					},
				],
			},
			{
				name: '跨域合作文創加值',
				item:
				[
					{
						name: '文創商品',
						item: ['C402', 'C403'],
					},
				],
			},
		],
	},
	// {
	//     layer: '機關預算',
	//     subLayer: [
	//         {
	//             name: '員工人數',
	//             item:
	//             [
	//                 {
	//                     name: '文化局暨附屬機關人事概況',
	//                     item: ['D201']
	//                 },
	//             ]
	//         },
	//         {
	//             name: '預算',
	//             item:
	//             [
	//                 {
	//                     name: '文化局暨所屬機關經費概況',
	//                     item: ['D101']
	//                 },
	//                 {
	//                     name: '文化局暨所屬機關歲入預決算概況',
	//                     item: ['D102']
	//                 },
	//                 {
	//                     name: '文化局暨所屬機關歲出預決算概況',
	//                     item: ['D103']
	//                 }
	//             ]
	//         },
	//         {
	//             name: '機關獲獎紀錄',
	//             item:
	//             [

	//                 {
	//                     name: '機關獲獎紀錄',
	//                     item: ['D301']
	//                 }
	//             ]
	//         },
	//         {
	//             name: '機關出版品',
	//             item:
	//             [

	//                 {
	//                     name: '機關出版品',
	//                     item: ['D302']
	//                 }
	//             ]
	//         },
	//     ],
	// }
]);

export const findBreadcrumb = (item) => {
	const breadCrumb = [];
	NAV_LAYER.forEach((layer) => {
		layer.subLayer.forEach((subLayer) => {
			subLayer.item.forEach((items) => {
				if (!items.item) return;
				if (items.item.includes(item)) {
					breadCrumb.push({ name: layer.layer, link: layer.link });
					breadCrumb.push({ name: subLayer.name });
					breadCrumb.push({ name: items.name });
				}
			});
		});
	});
	return breadCrumb;
};
